import axios from 'axios';
import { getIdToken } from '~/utils/kkpp';

const service = axios.create({
  // baseURL: appStore.baseUrl
  // timeout: 5000
});

// Request interceptors
service.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'application/json';
    config.headers['X-Requested-With'] = 'XMLHttpRequest';

    const token = getIdToken();

    if (token) {
      config.headers.Authorization = token;
    }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// Response interceptors
service.interceptors.response.use(
  response => {
    const res = response.data;
    return res;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 422 || error.response.status === 405) {
        return error.response.data;
      }
      // 認証エラー
      if (error.response.status === 401) {
        if (
          self.$nuxt.context.route.fullPath === '/' || // BAX-3117 特定の条件でTOPに遷移しようとするとログイン画面に遷移してしまう不具合の応急処置
          self.$nuxt.context.from.name === 'login' ||
          self.$nuxt.context.from.name === 'logout'
        ) {
          return null;
        }
        // location.href = '/login';
        self.$nuxt.context.error({
          statusCode: 401,
          message: '認証エラー'
        });
        // return null;
      }

      if (error.response.status === 400 || error.response.status === 403) {
        return null;
      }
      if (error.response.status !== 401 && error.response.status !== 404) {
        self.$nuxt.context.$sentry.captureException(error);
      }

      // TODO:暫定
      if (error.response.status === 500) {
        return null;
      }
      if (error.message === 'Network Error') {
        return null;
      }
    }
    console.log('予期せぬエラー発生', error);

    return null;
    // 処理を継続させるので
    // return Promise.reject(error);
  }
);

export default service;
