/* eslint-disable @typescript-eslint/no-var-requires */
import QRCode from 'qrcode';
import { IIdToken } from '@/types';
import { appStore, userStore, masterStore } from '@/store';
import { EXCLUSION_TEST_BAR, USER_TYPE, COMPANY_TYPE } from '@/configs';
import { saveUserOperation } from '@/apis/user';
import { getIdToken } from '@/utils/kkpp';

/**
 * クリップボードコピー
 *
 * @param {(string | null)} value
 * @return {*}  {boolean}
 */
export const clipboard = (value: string | null): boolean => {
  if (value === undefined || value === null) {
    return true;
  }
  // TODO:IE対応が必要な場合は現行のように
  // if( window.clipboardData ){
  //   window.clipboardData.setData('Text', value)
  //   return true;
  // }
  // このような対応が必要

  navigator.clipboard.writeText(value).catch((e: any) => {
    console.error('データ取得APIエラー', e);
    return false;
  });

  return true;
};

/**
 * Yappli用クリップボードコピー
 *
 * @param {(Node | null)} value
 * @return {*}  {boolean}
 */
export const clipboardForYappli = (value: Node | null): boolean => {
  // yappli以外の場合はclipboard()で対応可
  if (!appStore.appConfig.is_yappli_client) {
    return false;
  }

  if (value === undefined || value === null) {
    return true;
  }

  const range = document.createRange();
  range.selectNode(value);
  window.getSelection()?.removeAllRanges();
  window.getSelection()?.addRange(range);
  const r = document.execCommand('copy');
  if (!r) {
    return false;
  }

  return true;
};

/**
 * QRコード作成
 * @param {string} url
 * @param {number} width
 * @return {*}  {Promise<any>}
 */
export const generateQR = async (url: string, width: number): Promise<any> => {
  let code = '';
  try {
    code = await QRCode.toDataURL(url, { width });
  } catch (err) {
    console.error(err);
  }
  return code;
};

/**
 * ユーザー切替関連のlocalStorageをクリア
 */
export const removeSwitchInfo = (): void => {
  localStorage.removeItem('user_switch_back');
  localStorage.removeItem('user_switch_back_condition');
  localStorage.removeItem('user_switch_from');
};

/**
 * decodeJwt
 */
export const decodeJwt = (): IIdToken | null => {
  let result = {
    aud: '',
    ba_company_disable_create_shares_case: '',
    ba_company_enable_ba: '',
    ba_company_enable_baportal: '',
    ba_company_name: '',
    ba_company_type: '',
    ba_company_unique_code: '',
    ba_user_email: '',
    ba_user_name: '',
    ba_user_type: '',
    ba_user_unique_code: '',
    broker_code: '',
    broker_user_sub: '',
    exp: 0,
    iat: 0,
    iss: '',
    jwk: '',
    oit_code: '',
    realm: '',
    sub: '',
    user_switch_from: ''
  };

  const idToken: string = getIdToken();
  if (!idToken || idToken === 'false') {
    return null;
  }

  const base64Url = idToken.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  result = JSON.parse(decodeURIComponent(escape(window.atob(base64))));
  return result;
};

/**
 * 活動ログ書き込み
 */
export const writeUserOperation = (parms: {}): Promise<any> => {
  return saveUserOperation(parms);
};

/**
 * 金融機関事務局ユーザー判定
 */
export const isBankAdmin = (): boolean => {
  return (
    userStore.loginUser.user_type === 'system_admin' ||
    userStore.loginUser.user_type === 'bank_admin'
  );
};

/**
 * 金融機関支店管理者判定
 */
export const isBranchAdmin = (): boolean => {
  return userStore.loginUser.user_type === 'branch_admin';
};

/**
 * 担当支店の判定
 */
export const isUserBranchCode = (bb_code: string): boolean => {
  const codes =
    userStore.loginUser.user_branche_codes &&
    userStore.loginUser.user_branche_codes.find(
      (element) => element === bb_code
    );
  if (codes) {
    return true;
  } else {
    return false;
  }
};

/**
 * オーナーユーザー判定
 */
export const isOwner = (): boolean => {
  return (
    userStore.loginUser.user_type === 'company_owner' ||
    userStore.loginUser.user_type === 'partner_company_owner' ||
    userStore.loginUser.user_type === 'company_user'
  );
};

/**
 * アクセス可能の企業かどうかの判定
 */
export const isAccessibleCompany = (
  bb_code?: string | null,
  bb_id?: number | null,
  bb_unique_code?: string | null,
  bb_name?: string
): boolean => {
  if (isBankAdmin()) {
    return true;
  }

  if (bb_code) {
    return isUserBranchCode(bb_code);
  }

  let item = [...masterStore.bankBranch];

  if (bb_id) {
    item = item.filter((x) => x.bb_id === bb_id);
    if (item.length === 0) {
      return false;
    }
  }

  if (bb_unique_code) {
    item = item.filter((x) => x.bb_unique_code === bb_unique_code);
    if (item.length === 0) {
      return false;
    }
  }

  if (bb_name) {
    item = item.filter((x) => x.bb_name === bb_name);
    if (item.length === 0) {
      return false;
    }
  }
  return isUserBranchCode(item[0].bb_code);
};

/**
 * Yappli判定
 */
export const isYappli = (): boolean => {
  return appStore.appConfig.is_yappli_client;
};

/**
 * Fukuriを別タブで表示
 */
export function openFukuri(): void {
  window.open(
    process.env.FUKURI_URL + '/login?xba=' + appStore.appConfig.url + '&bank_code=' + appStore.appConfig.bank_code,
    '_blank'
  );
}

/**
 * Paymentを別タブで表示
 */
export function openPayment(): void {
  window.open(
    process.env.PAYMENT_URL + '?xba=' + appStore.baseUrl,
    '_blank'
  );
}

/**
 * google tag managerのヘッダーの設定取得
 */
export const getGoogleTagMangerHeaderSetting = (gaId: string, gtmId: string): any => {
  const script = [];
  if (gaId && appStore.appConfig.customize.enable_google_analytics_tag) {
    script.push(
      {
        async: true,
        src: `https://www.googletagmanager.com/gtag/js?id=${gaId}`
      },
      {
        innerHTML: `
        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', '${gaId}');
        `
      }
    );
  }
  if (gtmId) {
    script.push({
      innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${gtmId}');`
    });
  }

  let gtm_head = appStore.settingContents.google_tag_manager_head;
  if (gtm_head) {
    gtm_head = gtm_head.match(/<!-- Google Tag Manager -->/)
      ? gtm_head.replace(/<!-- Google Tag Manager -->/g, '')
      : gtm_head;
    gtm_head = gtm_head.match(/<!-- End Google Tag Manager -->/)
      ? gtm_head.replace(/<!-- End Google Tag Manager -->/g, '')
      : gtm_head;
    gtm_head = gtm_head.match(/<script>/)
      ? gtm_head.replace(/<script>/g, '')
      : gtm_head;
    gtm_head = gtm_head.match(/<\/script>/)
      ? gtm_head.replace(/<\/script>/g, '')
      : gtm_head;

    script.push({
      innerHTML: gtm_head
    });
  }

  return script;
};

/**
 * google tag managerのボディの設定取得
 */
export const getGoogleTagMangerBodySetting = (): any => {
  let body = appStore.settingContents.google_tag_manager_body;
  if (body) {
    body = body.match(/<!-- Google Tag Manager \(noscript\) -->/)
      ? body.replace(/<!-- Google Tag Manager \(noscript\) -->/g, '')
      : body;
    body = body.match(/<!-- End Google Tag Manager \(noscript\) -->/)
      ? body.replace(/<!-- End Google Tag Manager \(noscript\) -->/g, '')
      : body;
    body = body.match(/<noscript>/) ? body.replace(/<noscript>/g, '') : body;
    body = body.match(/<\/noscript>/)
      ? body.replace(/<\/noscript>/g, '')
      : body;
    return body;
  }
  return '';
};

/**
 * google tag managerのボディの設定取得
 */
export const getGoogleTagMangerBodySetting2 = (gtmId: string): any => {
  return `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
};

/**
 * Pendoのヘッダーの設定取得
 */
export const getPendoHeaderSetting = (): any => {
  const script = [];
  const id = masterStore.pendoAccount.id ? `'${masterStore.pendoAccount.id}'` : null;
  const type = masterStore.pendoAccount.type ? `'${masterStore.pendoAccount.type}'` : null;
  const industry_01 = masterStore.pendoAccount.industry_01 ? `'"${masterStore.pendoAccount.industry_01}"'` : null;
  const industry_02 = masterStore.pendoAccount.industry_02 ? `'"${masterStore.pendoAccount.industry_02}"'` : null;
  const industry_03 = masterStore.pendoAccount.industry_03 ? `'"${masterStore.pendoAccount.industry_03}"'` : null;
  const company_created_at = masterStore.pendoAccount.company_created_at ? `'${masterStore.pendoAccount.company_created_at}'` : null;
  const company_is_charge_target = masterStore.pendoAccount.company_is_charge_target ? `'${masterStore.pendoAccount.company_is_charge_target}'` : null;
  const company_charge_start_at = masterStore.pendoAccount.company_charge_start_at ? `'${masterStore.pendoAccount.company_charge_start_at}'` : null;
  const company_interesting_features = masterStore.pendoAccount.company_interesting_features ? JSON.stringify(masterStore.pendoAccount.company_interesting_features) : null;
  const homepage = masterStore.pendoAccount.homepage ? `'${masterStore.pendoAccount.homepage}'` : null;
  const company_issues = masterStore.pendoAccount.company_issues ? `'"${masterStore.pendoAccount.company_issues.replace(/\n/g, '\\n')}"'` : '""';
  const company_forte = masterStore.pendoAccount.company_forte ? `'"${masterStore.pendoAccount.company_forte.replace(/\n/g, '\\n')}"'` : '""';
  const company_service = masterStore.pendoAccount.company_service ? `'"${masterStore.pendoAccount.company_service.replace(/\n/g, '\\n')}"'` : '""';
  const company_remarks = masterStore.pendoAccount.company_remarks ? `'"${masterStore.pendoAccount.company_remarks.replace(/\n/g, '\\n')}"'` : '""';
  const company_corporate_site = masterStore.pendoAccount.company_corporate_site ? `'"${masterStore.pendoAccount.company_corporate_site}"'` : '""';
  const company_demo_accounts = masterStore.pendoAccount.company_demo_accounts ? `'${masterStore.pendoAccount.company_demo_accounts}'` : '\'\'';
  const pendoHead = `
    (function(apiKey){
        (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
        v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
            o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
            y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
            z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
    
            pendo.initialize({
                visitor: {
                    id:                           '${userStore.loginUser.user_unique_code}',
                    role:                         '${userStore.loginUser.user_type_name}',
                    user_bank_code:               '${appStore.appConfig.bank_code}',
                },
                account: {
                    id:                           ${id},
                    type:                         ${type},
                    industry_01:                  ${industry_01},
                    industry_02:                  ${industry_02},
                    industry_03:                  ${industry_03},
                    company_id:                   ${masterStore.pendoAccount.company_id},
                    company_created_at:           ${company_created_at},
                    company_is_charge_target:     ${company_is_charge_target},
                    company_charge_amount:        ${masterStore.pendoAccount.company_charge_amount},
                    company_charge_start_at:      ${company_charge_start_at},
                    company_employee_count:       ${masterStore.pendoAccount.company_employee_count},
                    company_matching_count:       ${masterStore.pendoAccount.company_matching_count},
                    company_interesting_features: ${company_interesting_features},
                    homepage:                     ${homepage},
                    company_issues:               ${company_issues},
                    company_forte:                ${company_forte},
                    company_service:              ${company_service},
                    company_remarks:              ${company_remarks},
                    company_corporate_site:       ${company_corporate_site},
                    company_demo_accounts:        ${company_demo_accounts},
                    company_employee_accounts:    ${masterStore.pendoAccount.company_employee_accounts},
                    company_business_accounts:    ${masterStore.pendoAccount.company_business_accounts},
                    company_coupons:              ${masterStore.pendoAccount.company_coupons},
                }
            });
    })('bfe3cd0a-e60b-42ba-7fbf-8cf2569fc092');
  `;
  script.push({
    innerHTML: pendoHead
  });
  return script;
};

/**
 * Pendoの金融機関管理画面のヘッダー設定取得
 */
export const getAdminPendoHeaderSetting = (): any => {
  const script = [];
  const id = masterStore.pendoBankerAccount.id ? `"${masterStore.pendoBankerAccount.id}"` : '""';
  const role = masterStore.pendoBankerAccount.role ? `"${masterStore.pendoBankerAccount.role}"` : '""';
  const banker_status = masterStore.pendoBankerAccount.banker_status ? `"${masterStore.pendoBankerAccount.banker_status}"` : '""';
  const banker_branch_office = masterStore.pendoBankerAccount.banker_branch_office ? `'"${masterStore.pendoBankerAccount.banker_branch_office}"'` : '""';
  const bank_code = masterStore.pendoBankerAccount.bank_code ? `"${masterStore.pendoBankerAccount.bank_code}"` : '""';
  const normal_company_count = masterStore.pendoBankerAccount.normal_company_count ? masterStore.pendoBankerAccount.normal_company_count : 0;
  const business_accounts_setting = masterStore.pendoBankerAccount.business_accounts_setting ? `"${masterStore.pendoBankerAccount.business_accounts_setting}"` : '""';
  const ba_portal_setting = masterStore.pendoBankerAccount.ba_portal_setting ? `"${masterStore.pendoBankerAccount.ba_portal_setting}"` : '""';
  const profit_matching_setting = masterStore.pendoBankerAccount.profit_matching_setting ? `"${masterStore.pendoBankerAccount.profit_matching_setting}"` : '""';
  const ba_mall_setting = masterStore.pendoBankerAccount.ba_mall_setting ? `"${masterStore.pendoBankerAccount.ba_mall_setting}"` : '""';
  const campfire_setting = masterStore.pendoBankerAccount.campfire_setting ? `"${masterStore.pendoBankerAccount.campfire_setting}"` : '""';
  const pendoHead = `
    (function(apiKey){
        (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
        v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
            o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
            y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
            z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
    
            pendo.initialize({
                visitor: {
                    id:                        ${id},
                    role:                      ${role},
                    banker_status:             ${banker_status},
                    banker_branch_office:      ${banker_branch_office},
                },
                account: {
                    id:                        ${bank_code},
                    normal_company_count:      ${normal_company_count},
                    business_accounts_setting: ${business_accounts_setting},
                    ba_portal_setting:         ${ba_portal_setting},
                    profit_matching_setting:   ${profit_matching_setting},
                    ba_mall_setting:           ${ba_mall_setting},
                    campfire_setting:          ${campfire_setting},
                }
            });
    })('bfe3cd0a-e60b-42ba-7fbf-8cf2569fc092');
  `;

  script.push({
    innerHTML: pendoHead
  });
  return script;
};
/**
 * YappliのPush通知を受け取るような設定するかどうか判定する
 */
export const isNeedYappli = (): boolean => {
  if (!isYappli()) {
    return false;
  }

  if (userStore.loginUser.user_unique_code === '') {
    return false;
  }

  return true;
};

/**
 * SHARES SITEへのリンク
 * @param user_id string
 */
export const openSharesSite = (user_id: string): void => {
  const sharesUserURL = `${process.env.SHARES_API_URL}/list/user/${user_id}`;
  if (isYappli()) {
    location.href = sharesUserURL;
  } else {
    window.open(sharesUserURL, '_blank');
  }
};

/**
 * LocalStorageが利用可能か
 */
export const isLocalStorageavAilable = (): boolean => {
  if (typeof localStorage !== 'undefined') {
    try {
      localStorage.setItem('dummy', '1');
      if (localStorage.getItem('dummy') === '1') {
        localStorage.removeItem('dummy');
        return true;
      } else {
        self.$nuxt.$sentry.captureMessage('localStorageが無効になっている');
        return false;
      }
    } catch (e) {
      self.$nuxt.$sentry.captureMessage('localStorageの機能が使えない');
      return false;
    }
  } else {
    self.$nuxt.$sentry.captureMessage('localStorageが未対応');
    return false;
  }
};

/**
 * ログインのリダイレクト状態（ログイン直後の状態を取得するたい利用する）
 */
export const setRedirectLogin = (val: string): void => {
  localStorage.setItem('redirectLogin', val);
};

/**
 * ログインのリダイレクト状態（ログイン直後の状態を取得するため利用する）
 */
export const redirectLogin = (): boolean => {
  const result = localStorage.getItem('redirectLogin') || null;
  return !result;
};

/**
 * テストバーを表示するか (本番環境以外はテスト用のバーを表示する)
 * @return boolean
 */
export const testBar = (): boolean => {
  if (process.env.NAME === EXCLUSION_TEST_BAR) {
    return false;
  } else {
    return true;
  }
};

/**
 * Yappliの場合はダウンロードリンクをブラウザで開くよう処理する
 * @param url string
 * @return string
 */
export const encodeUrl = (url: string): string => {
  if (!isYappli()) {
    return url;
  }

  const encodedUrl = encodeURI(url);
  return 'native:/action/open_browser?url=' + encodedUrl;
};

/**
 * 商談関連チャット差込フィールド設定が利用可能かどうか
 */
export const useFfxedphrase = (type: string): boolean => {
  const array = ['INTERBANK_MATCHING', 'INTERBANK_PARTNER', 'SUPPORT_BY_BANK'];

  return array.includes(type);
};

/**
 * campfireのnewバッジを表示するかどうか
 */
export const isShowCampfireNewBadge = (formatTodayDate: string): boolean => {
  if (appStore.appConfig.enable_campfire) {
    const campfireNewBadgeShowDate = localStorage
      .getItem('campfire_new_badge_show_date')
      ?.toString();
    if (!campfireNewBadgeShowDate) {
      localStorage.setItem('campfire_new_badge_show_date', formatTodayDate);

      // 初期ログインで表示を行う
      return true;
    } else {
      const today = new Date();
      const showDate = new Date(campfireNewBadgeShowDate);

      // 表示期間日付を計算
      // 分
      if (appStore.appConfig.campfire_new_badge_display_period.match(/min/)) {
        const minutes = Number(
          appStore.appConfig.campfire_new_badge_display_period.replace(
            ' min',
            ''
          )
        );
        showDate.setMinutes(showDate.getMinutes() + minutes);
        // 時
      } else if (
        appStore.appConfig.campfire_new_badge_display_period.match(/month/)
      ) {
        const month = Number(
          appStore.appConfig.campfire_new_badge_display_period.replace(
            ' month',
            ''
          )
        );
        showDate.setMonth(showDate.getMonth() + month);
      }

      // 日付が過ぎていたらnewのバッジを消す
      if (today > showDate) {
        // バッジを消す
      } else {
        // それ以外なら表示
        return true;
      }
    }
  }

  return false;
};

/**
 * 事業承継のnewバッジを表示するかどうか
 */
export const isShowBusinessSuccessionNewBadge = (formatTodayDate: string): boolean => {
  if (appStore.appConfig.enable_business_succession) {
    const businessSuccessionNewBadgeShowDate = localStorage.getItem('business_succession_new_badge_show_date')?.toString();
    if (!businessSuccessionNewBadgeShowDate) {
      localStorage.setItem('business_succession_new_badge_show_date', formatTodayDate);

      // 初期ログインで表示を行う
      return true;
    } else {
      const today = new Date();
      const showDate = new Date(businessSuccessionNewBadgeShowDate);

      // 表示期間日付を計算
      // 分
      if (appStore.appConfig.business_succession_new_badge_display_period.match(/min/)) {
        const minutes = Number(appStore.appConfig.business_succession_new_badge_display_period.replace(' min', ''));
        showDate.setMinutes(showDate.getMinutes() + minutes);
        // 時
      } else if (appStore.appConfig.business_succession_new_badge_display_period.match(/month/)) {
        const month = Number(appStore.appConfig.business_succession_new_badge_display_period.replace(' month', ''));
        showDate.setMonth(showDate.getMonth() + month);
      }

      // 日付が過ぎていたら
      if (today > showDate) {
        // バッジを消す
      } else {
        // それ以外なら表示
        return true;
      }
    }
  }

  return false;
};

/**
 * 勤怠連携サービスのnewバッジを表示するかどうか
 */
export const isShowIcnextLinkageNewBadge = (formatTodayDate: string): boolean => {
  if (!appStore.appConfig.enable_icnext) {
    return false;
  }

  const attendanceLinkageNewBadgeShowDate = localStorage.getItem('icnext_new_badge_display_period')?.toString();
  if (!attendanceLinkageNewBadgeShowDate) {
    localStorage.setItem('icnext_new_badge_display_period', formatTodayDate);

    // 初期ログインで表示を行う
    return true;
  }
  const today = new Date();
  const showDate = new Date(attendanceLinkageNewBadgeShowDate);

  // 表示期間日付を計算
  // 分
  if (appStore.appConfig.icnext_new_badge_display_period.match(/min/)) {
    const minutes = Number(appStore.appConfig.icnext_new_badge_display_period.replace(' min', ''));
    showDate.setMinutes(showDate.getMinutes() + minutes);
  // 時
  } else if (appStore.appConfig.icnext_new_badge_display_period.match(/month/)) {
    const month = Number(appStore.appConfig.icnext_new_badge_display_period.replace(' month', ''));
    showDate.setMonth(showDate.getMonth() + month);
  }

  // 日付が過ぎていたら
  if (today > showDate) {
    // バッジを消す
    return false;
  } else {
    // それ以外なら表示
    return true;
  }
};

/**
 * ちゃんと請求書サービスのnewバッジを表示するかどうか
 */
export const isShowPaymentNewBadge = (formatTodayDate: string): boolean => {
  if (appStore.appConfig.enable_payment || appStore.appConfig.enable_payment_receive) {
    const paymentNewBadgeShowDate = localStorage.getItem('payment_new_badge_show_date')?.toString();
    if (!paymentNewBadgeShowDate) {
      localStorage.setItem('payment_new_badge_show_date', formatTodayDate);
      // 初期ログインで表示を行う
      return true;
    } else {
      const today = new Date();
      const showDate = new Date(paymentNewBadgeShowDate);

      // 表示期間日付を計算
      // 分
      if (appStore.appConfig.payment_new_badge_display_period.match(/min/)) {
        const minutes = Number(appStore.appConfig.payment_new_badge_display_period.replace(' min', ''));
        showDate.setMinutes(showDate.getMinutes() + minutes);
        // 時
      } else if (appStore.appConfig.payment_new_badge_display_period.match(/day/)) {
        const days = Number(appStore.appConfig.payment_new_badge_display_period.replace(' day', ''));
        showDate.setDate(showDate.getDate() + days);
      }

      // 日付が過ぎていたら
      if (today > showDate) {
        // バッジを消す
      } else {
        // それ以外なら表示
        return true;
      }
    }
  }

  return false;
};

/**
 * ディープコピー用の関数（値をリアクティブにさせたくない時に使用するクローン関数）
 * @param obj
 */
export const deepCopy = (obj: {} | []): {} | [] => {
  return JSON.parse(JSON.stringify(obj));
};

/**
 * ログイン中ユーザーが通常企業かどうか
 */
export const isNormalCompanyType = (): boolean => {
  return userStore.loginUser.company_type === COMPANY_TYPE.NORMAL;
};

/**
 * ログイン中ユーザーがパートナー企業かどうか
 */
export const isPartnerCompanyType = (): boolean => {
  return userStore.loginUser.company_type === COMPANY_TYPE.PARTNER;
};

/**
 * ログイン中ユーザーがオーナーかどうか
 */
export const isOwnerUserType = (): boolean => {
  return userStore.loginUser.user_type === USER_TYPE.OWNER;
};

/**
 * ログイン中ユーザーがビジネスユーザーかどうか
 */
export const isBusinessUserType = (): boolean => {
  return userStore.loginUser.user_type === USER_TYPE.USER;
};

/**
 * ログイン中ユーザーがパートナーオーナーかどうか
 */
const isPartnerOwnerUserType = (): boolean => {
  return userStore.loginUser.user_type === USER_TYPE.PARTNER_OWNER;
};

/**
 * ログイン中ユーザーがパートナー企業オーナーかどうか
 */
export const isPartnerOwnerUser = (): boolean => {
  /**
   * パートナー企業のオーナー」の判定方法は以下2種類ある
   * 1. ユーザータイプが「パートナー企業オーナー」
   * 2. ユーザータイプが「オーナー」かつ企業タイプが「パートナー」
   */
  return isPartnerOwnerUserType() || (isOwnerUserType() && isPartnerCompanyType());
};

/**
 * 企業タイプがポータルかどうか
 */
export const isCompanyTypePortal = (companyType: string): boolean => {
  return companyType === COMPANY_TYPE.BA_PORTAL;
};
