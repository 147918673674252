var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.appStore.appConfig.is_yappli_client
    ? _c(
        "div",
        {
          directives: [
            {
              name: "resize",
              rawName: "v-resize",
              value: _vm.onResize,
              expression: "onResize",
            },
          ],
        },
        [
          _c(
            "v-app-bar",
            {
              staticClass: "white",
              style: _vm.ajustment,
              attrs: { app: "", "clipped-left": "", dense: "", height: "53px" },
            },
            [
              !_vm.isMobile && _vm.switched
                ? _c(
                    "v-item-group",
                    [
                      _c("v-item", [
                        _c(
                          "a",
                          {
                            staticStyle: { cursor: "pointer" },
                            on: { click: _vm.cancelSwitching },
                          },
                          [
                            _vm.device === "pc"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pt-2 pb-1 text-center red darken-3 mr-1",
                                    staticStyle: {
                                      width: "180px",
                                      height: "54px",
                                    },
                                  },
                                  [
                                    _c("v-icon", {
                                      attrs: { dark: "" },
                                      domProps: {
                                        textContent:
                                          _vm._s("mdi-account-switch"),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "caption white--text" },
                                      [_vm._v("ユーザー切替モード(戻る)")]
                                    ),
                                  ],
                                  1
                                )
                              : _c(
                                  "div",
                                  {
                                    staticClass:
                                      "pt-2 pb-1 px-5 text-center red darken-3",
                                    staticStyle: { height: "54px" },
                                  },
                                  [
                                    _c("v-icon", {
                                      attrs: { dark: "" },
                                      domProps: {
                                        textContent:
                                          _vm._s("mdi-account-switch"),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "caption white--text" },
                                      [_vm._v("切替")]
                                    ),
                                  ],
                                  1
                                ),
                          ]
                        ),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.device === "tablet" && _vm.showMenu
                ? _c("ApplicationBarToggleButton", {
                    staticStyle: { "margin-left": "10px" },
                    attrs: { "badge-count": _vm.badgeCount },
                    on: { "handle-click": _vm.toggleMenu },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-row",
                { staticClass: "ba_logo" },
                [
                  _vm._t("img", function () {
                    return [
                      _c("nuxt-link", { attrs: { to: "/" } }, [
                        _c("img", {
                          attrs: {
                            src:
                              _vm.$config.assetUrl +
                              "/customize/" +
                              _vm.appStore.systemPrefix +
                              "/ba_logo.png",
                          },
                        }),
                      ]),
                    ]
                  }),
                  _vm._v(" "),
                  _vm.showUserProfile &&
                  _vm.loggedIn &&
                  !_vm.isMobile &&
                  _vm.device === "pc"
                    ? _c(
                        "v-item-group",
                        {
                          staticClass: "d-flex justify-start",
                          staticStyle: { "margin-left": "20px" },
                        },
                        [
                          _c(
                            "v-item",
                            [
                              _c("UserProfile", {
                                staticStyle: { "margin-top": "-35px" },
                                attrs: {
                                  "user-last-name":
                                    _vm.profileData.userLastName,
                                  "user-first-name":
                                    _vm.profileData.userFirstName,
                                  "company-name": _vm.profileData.companyName,
                                  "image-url": _vm.profileData.imageUrl,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.loggedIn && _vm.device !== "sp"
                    ? _c(
                        "v-item-group",
                        { staticClass: "d-flex justify-end" },
                        [
                          !_vm.hideLogoutButton
                            ? _c("v-item", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "pb-1 text-center",
                                    staticStyle: {
                                      cursor: "pointer",
                                      "margin-top": "-45px",
                                    },
                                  },
                                  [
                                    _c("NavigationMainButton", {
                                      attrs: {
                                        "icon-name": "mdi-logout",
                                        title: "ログアウト",
                                        width: 80,
                                      },
                                      on: {
                                        "handle-click": function ($event) {
                                          return _vm.$router.push("/logout")
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.showHelpButton
                            ? _c("v-item", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "pb-1 pr-6 text-center",
                                    staticStyle: {
                                      cursor: "pointer",
                                      "margin-top": "-45px",
                                    },
                                  },
                                  [
                                    _c("NavigationMainButton", {
                                      attrs: {
                                        "icon-name": "mdi-help-circle-outline",
                                        title: "ヘルプ",
                                        width: 80,
                                      },
                                      on: { "handle-click": _vm.openHelp },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.loggedIn && _vm.device === "sp"
                    ? _c(
                        "v-item-group",
                        { staticClass: "d-flex justify-end align-center" },
                        [
                          _vm.showHelpButton
                            ? _c("v-item", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "pt-1 text-center",
                                    staticStyle: {
                                      cursor: "pointer",
                                      "margin-top": "-40px",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          rounded: "",
                                          plain: "",
                                          width: "50",
                                          height: "50",
                                          elevation: "0",
                                          ripple: false,
                                        },
                                        on: { click: _vm.openHelp },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-help-circle-outline"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.hideLogoutButton
                            ? _c("v-item", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "pt-1 pr-3 text-center",
                                    staticStyle: {
                                      cursor: "pointer",
                                      "margin-top": "-40px",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          rounded: "",
                                          plain: "",
                                          width: "50",
                                          height: "50",
                                          elevation: "0",
                                          ripple: false,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.$emit("toggle-setting")
                                          },
                                        },
                                      },
                                      [
                                        _c("v-icon", [
                                          _vm._v("mdi-cog-outline"),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.testBar() ? _c("div", { staticClass: "test-border" }) : _vm._e(),
        ],
        1
      )
    : _c(
        "div",
        [
          _vm.showMenu && _vm.$vuetify.breakpoint.width > 480
            ? _c(
                "v-btn",
                {
                  staticClass: "mx-2",
                  attrs: {
                    fixed: "",
                    small: "",
                    right: "",
                    top: "",
                    color: "primary",
                    elevation: "2",
                    fab: "",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.toggleMenu.apply(null, arguments)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-menu")])],
                1
              )
            : _vm._e(),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }