var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "user-profile d-flex",
      style: _vm.imageUrl ? "gap: 5px;" : "",
    },
    [
      _c("div", { staticClass: "left" }, [
        _vm.imageUrl
          ? _c("img", {
              staticClass: "company-logo",
              attrs: { src: _vm.imageUrl },
            })
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right d-flex flex-column align-start justify-center" },
        [
          _vm.companyName
            ? _c("p", { staticClass: "company-name mb-0" }, [
                _vm._v("\n      " + _vm._s(_vm.companyName) + "\n    "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.userLastName || _vm.userFirstName
            ? _c("p", { staticClass: "user-name mb-0" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.userLastName) +
                    " " +
                    _vm._s(_vm.userFirstName) +
                    "さん\n    "
                ),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }