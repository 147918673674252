import { render, staticRenderFns } from "./register-upgrade-hgba.vue?vue&type=template&id=90f1bb56&scoped=true&"
import script from "./register-upgrade-hgba.vue?vue&type=script&lang=ts&"
export * from "./register-upgrade-hgba.vue?vue&type=script&lang=ts&"
import style0 from "./register-upgrade-hgba.vue?vue&type=style&index=0&id=90f1bb56&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90f1bb56",
  null
  
)

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VMain } from 'vuetify/lib/components/VMain';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VApp,VMain,VOverlay,VProgressCircular,VSnackbar})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('90f1bb56')) {
      api.createRecord('90f1bb56', component.options)
    } else {
      api.reload('90f1bb56', component.options)
    }
    module.hot.accept("./register-upgrade-hgba.vue?vue&type=template&id=90f1bb56&scoped=true&", function () {
      api.rerender('90f1bb56', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "layouts/register-upgrade-hgba.vue"
export default component.exports