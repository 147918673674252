// KKPPをIDPとする金融機関コード一覧
const KKPP_BANK_CODE: { value: string }[] = [
  { value: '0182' } // 肥後銀行
];
const bamidpTokenKey = 'auth._token.bamidp';
const tokenPrefix = 'auth._token.bamidp_bank';
const strategyKey = 'auth.strategy';

// ローカルストレージに有効なトークンが存在するかチェック
export const checkLocalStorage = (): boolean => {
  const isTokenInvalid = (key: string): boolean => {
    const token = localStorage.getItem(key);
    return !token || token === 'false';
  };

  // デフォルトストラテジーであるbamidpをチェック
  if (isTokenInvalid(bamidpTokenKey)) {
    // 存在しない場合はkkpp用に実装したストラテジーをチェック
    const hasValidKKPPToken = KKPP_BANK_CODE.some(codeObj => {
      const bankCode = codeObj.value;
      return !isTokenInvalid(`${tokenPrefix}${bankCode}`);
    });

    // 全てのストラテジーが無効な場合はtrue
    return !hasValidKKPPToken;
  }

  // デフォルトトークンが有効な場合は false を返す
  return false;
};

// IdTokenの取得
export const getIdToken = (): string => {
  const getToken = (key: string): string | null => {
    const token = localStorage.getItem(key);
    return token && token !== 'false' ? token : null;
  };

  // bamidpのトークンが存在する場合はそれを返す
  const bamidpToken = getToken(bamidpTokenKey);
  if (bamidpToken) {
    return bamidpToken;
  }

  // 存在しない場合はkkpp用に実装したストラテジーをチェック
  for (const codeObj of KKPP_BANK_CODE) {
    const bankCode = codeObj.value;
    const bankToken = getToken(`${tokenPrefix}${bankCode}`);
    if (bankToken) {
      return bankToken;
    }
  }

  // どちらのトークンも存在しない場合は空文字を返す
  return '';
};

// nuxt/authのstrategyを設定
export const setStrategy = (bankCode: string): string => {
  for (const codeObj of KKPP_BANK_CODE) {
    if (codeObj.value === bankCode) {
      return `bamidp_bank${bankCode}`;
    }
  }
  return 'bamidp';
};

// BA-KKPPを使用している金融機関か判定
export const isKKPP = (bankCode: string): boolean => {
  return KKPP_BANK_CODE.some(codeObj => codeObj.value === bankCode);
};

/**
 * strategyからBA-KKPPを使用している金融機関か判定
 */
export const isKKPPFromStrategy = (): boolean => {
  const strategy = localStorage.getItem(strategyKey);
  if (!strategy) {
    return false;
  }
  return KKPP_BANK_CODE.some(codeObj => strategy === `bamidp_bank${codeObj.value}`);
};